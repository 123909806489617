import firebase from 'gatsby-plugin-firebase';

export const isAdmin = uid => {
  return new Promise((resolve, reject) => {
    if (!uid) {
      reject({ message: 'not login' });
    }
    const docRef = firebase
      .firestore()
      .collection('users')
      .doc(uid);
    docRef
      .get()
      .then(doc => {
        console.log('doc eeeeee', doc)
        if (doc.exists) {
          if (doc.data().type === 'admin') {
            resolve(true);
          } else {
            resolve(false);
          }
        } else {
          console.log('No such document!');
          resolve(false);
        }
      })
      .catch(error => {
        console.log('Error getting document:', error);
        reject(error);
      });
  });
};
