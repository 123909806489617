import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import constants from '../../constants';
import Image from '../Image';
import './style.sass';

export default () => {
  const [classes, setClasses] = useState('header__logo');
  useEffect(() => {
    if (window.location.pathname !== '/') {
      setClasses(`${classes} header__logo--small`);
    }
  }, []);
  return (
    <>
      <header className="header">
        <Link to="/" className={classes}>
          <Image filename="logo_640x320.png" alt={constants.siteName} />
        </Link>
      </header>
    </>
  );
};
