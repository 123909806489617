import React from 'react';
import { Link } from 'gatsby';
import constants from '../../constants';
import Image from '../Image';
import './style.sass';

export default () => {
  return (
    <footer className="footer">
      <figure className="footer__figure">
        <Image filename="img_normal.png" />
      </figure>
      <nav>
        {/* <div className="footer__item">
          <Link to="/">夢辞典</Link>
        </div> */}
        <div className="footer__item">
          <Link to="/about">マダムたぬきとは</Link>
        </div>
        <div className="footer__item">
          <Link to="/overview">夢占いとは</Link>
        </div>
        <div className="footer__item">
          <Link to="/terms">利用規約</Link>
        </div>
        <div className="footer__item">
          {/* <Link to="/contact">お問い合わせ</Link> */}
          <a href="mailto:pokopons001@gmail.com?subject=お問い合わせ | マダムたぬき">
            お問い合わせ
          </a>
        </div>
      </nav>
      <div className="footer__cp">@{constants.siteName}</div>
    </footer>
  );
};
