import React, { useState, useEffect } from 'react';
// import { navigate } from '@reach/router';
import constants from '../constants';
import { isAdmin } from '../firebase';
import { getUser } from '../utils';
import Header from './Header';
import Footer from './Footer';

export default ({ children }) => {
  const [isRead, setIsRead] = useState(false);
  useEffect(() => {
    if (window.location.hostname === constants.host.development) {
      isAdmin(getUser().uid)
        .then(res => setIsRead(res))
        .catch(error => console.log(error));
    } else {
      setIsRead(true);
    }
  }, []);
  return isRead ? (
    <>
      <Header />
      <main>{children}</main>
      <Footer />
    </>
  ) : null;
};
