import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

export default ({ filename, alt }) => {
  const data = useStaticQuery(graphql`
    query {
      images: allFile {
        edges {
          node {
            relativePath
            name
            childImageSharp {
              sizes(maxWidth: 800) {
                ...GatsbyImageSharpSizes
              }
            }
          }
        }
      }
    }
  `);

  const image = data.images.edges.find(n => {
    return n.node.relativePath.includes(filename);
  });

  if (!image) return;

  // Imgタグでgatsby-imageで最適化された画像を表示する
  const imageSizes = image.node.childImageSharp.sizes;
  return <Img sizes={imageSizes} alt={alt} />;
};
